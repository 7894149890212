import React from 'react';
import Formulario from '../../commons/Formulario';
import Titulo from '../../components/Titulo';

import logo from '../../assets/nosotros/titulo.png';
import BlogList from './BlogList';

import '../../css/pages/blog.css';
import '../../css/commons/carrusel.css';
import '../../css/commons/paginacion.css';

const Blog = () => {
  const initialState = {
    params: {
      st: '1',
    },
    rows: 6,
  };
  return (
    <>
      <Titulo titulo="Blog de noticias" imagen={logo} />
      <Formulario initialState={initialState}>
        <BlogList />
      </Formulario>
    </>
  );
};

export default Blog;
