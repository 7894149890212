import React from 'react';
import { useTablaPagination } from './Formulario';

const Paginacion = () => {
  const { goNextPage, goPrevPage, activePageIndex, rows, total } =
    useTablaPagination();

  const handlePage = () => {
    return Math.trunc(activePageIndex / rows) + 1;
  };
  const handleTotal = () => {
    return Math.trunc(total / rows) + 1;
  };
  return (
    <div className="cb-paginacion">
      <span className="count">{`${handlePage()} / ${handleTotal()}`}</span>
      <button
        className="pagina left"
        disabled={handlePage() === 1}
        onClick={goPrevPage}
      >
        <i></i>
        <i></i>
      </button>
      <button
        className="pagina right"
        disabled={handlePage() === handleTotal()}
        onClick={goNextPage}
      >
        <i></i>
        <i></i>
      </button>
    </div>
  );
};

export default Paginacion;
