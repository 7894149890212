import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import Authenticate from '../components/Authenticate';

import '../css/header.css';

const Header = () => {
  const handleHeader = (e) => {
    e.target
      .closest('nav.cb-header-contenedor')
      .querySelector("input[type='checkbox']").checked = false;
  };

  return (
    <div className="cb-header">
      <nav className="cb-header-contenedor">
        <input type="checkbox" id="checkmenu"></input>
        <label htmlFor="checkmenu" className="checkButton">
          <span></span>
          <span></span>
          <span></span>
        </label>
        <div className="cb-logo">
          <Link to="/"></Link>
        </div>
        <ul>
          <li>
            <NavLink
              exact
              to="/"
              activeClassName="active"
              onClick={(e) => handleHeader(e)}
            >
              Inicio
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/financiamiento"
              activeClassName="active"
              onClick={(e) => handleHeader(e)}
            >
              Financiamiento
            </NavLink>
          </li>
           */}
          <li>
            <NavLink
              to="/nosotros"
              activeClassName="active"
              onClick={(e) => handleHeader(e)}
            >
              Nosotros
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blog"
              activeClassName="active"
              onClick={(e) => handleHeader(e)}
            >
              Blog
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/contacto"
              activeClassName="active"
              onClick={(e) => handleHeader(e)}
            >
              Contacto
            </NavLink>
          </li> */}
        </ul>
        <Authenticate />
      </nav>
    </div>
  );
};

export default Header;
