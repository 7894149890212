import React, { useEffect, useReducer } from 'react';

import { api } from '../redux/axios/api';

const FormularioContext = React.createContext();

export const useFormularioContext = () => {
  const context = React.useContext(FormularioContext);
  if (!context) {
    throw new Error(
      '!El componente compuesto no puede ser renderizado fuera de formulario'
    );
  }
  return context;
};

export const useTablaPagination = () => {
  const {
    goNextPage,
    goPrevPage,
    goSelectedPage,
    activePageIndex,
    rows,
    total,
  } = useFormularioContext();
  return {
    goNextPage,
    goPrevPage,
    goSelectedPage,
    activePageIndex,
    rows,
    total,
  };
};

export const useTablaBody = () => {
  const { processing, setRows, setSelectedItem, data, getData, selected } =
    useFormularioContext();
  return {
    processing,
    data,
    getData,
    setRows,
    setSelectedItem,
    selected,
  };
};

export const useTablaSearch = () => {
  const { setSearch, search } = useFormularioContext();
  return {
    setSearch,
    search,
  };
};

export const useTablaFilter = () => {
  const { getData, params, dispatch } = useFormularioContext();
  return { getData, params, dispatch };
};

const defaultInitialState = {
  activePageIndex: 1,
  rows: 10,
  data: [],
  total: 0,
  processing: false,
  serviceName: null,
  search: null,
  selected: null,
  params: {},
};

export const actions = {
  NEXT_PAGE: 'NEXT_PAGE',
  PREV_PAGE: 'PREV_PAGE',
  SELECTED_PAGE: 'SELECTED_PAGE',
  SELECTED_ITEM: 'SELECTED_ITEM',
  SET_ROWS: 'SET_ROWS',
  SET_SERVICE: 'SET_SERVICE',
  GET_DATA: 'GET_DATA',
  SET_PROCESS: 'SET_PROCESS',
  SET_SEARCH: 'SET_SEARCH',
};

const combineReducer =
  (...reducers) =>
  (state, action) => {
    return reducers.reduce((acc, nextReducer) => {
      return nextReducer(acc, action);
    }, state);
  };

const formularioReducer = (state, action) => {
  switch (action.type) {
    case actions.NEXT_PAGE:
      return { ...state, activePageIndex: action.payload };
    case actions.PREV_PAGE:
      return { ...state, activePageIndex: action.payload };
    case actions.SELECTED_PAGE:
      return { ...state, activePageIndex: action.payload };
    case actions.SELECTED_ITEM:
      return { ...state, selected: action.payload };
    case actions.SET_ROWS:
      return { ...state, rows: action.payload };
    case actions.SET_SERVICE:
      return { ...state, serviceName: action.payload };
    case actions.SET_PROCESS:
      return { ...state, processing: action.payload };
    case actions.SET_SEARCH:
      return { ...state, search: action.payload, activePageIndex: 1 };
    case actions.GET_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.count,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => state;

const Formulario = ({
  children,
  reducer = defaultReducer,
  initialState = {},
}) => {
  const [state, dispatch] = useReducer(
    combineReducer(formularioReducer, reducer),
    {
      ...defaultInitialState,
      ...initialState,
    }
  );

  useEffect(() => {
    getData();
  }, [state.activePageIndex, state.search]);

  const goNextPage = () => {
    let p = Math.trunc(state.activePageIndex / state.rows) + 1;
    dispatch({ type: actions.NEXT_PAGE, payload: p * state.rows + 1 });
  };

  const goPrevPage = () => {
    let p = Math.trunc(state.activePageIndex / state.rows) - 1;
    dispatch({ type: actions.PREV_PAGE, payload: p * state.rows + 1 });
  };

  const goSelectedPage = (n) => {
    dispatch({ type: actions.SELECTED_PAGE, payload: n + 1 });
  };

  const setSelectedItem = (n) => {
    dispatch({ type: actions.SELECTED_ITEM, payload: n });
  };

  const setRows = (n) => {
    dispatch({ type: actions.SET_ROWS, payload: n });
  };

  const setSearch = (n) => {
    dispatch({ type: actions.SET_SEARCH, payload: n });
  };

  const getData = (n) => {
    if (n) {
      dispatch({ type: actions.SET_SERVICE, payload: n });
    }
    if (state.serviceName || n) {
      let url = state.serviceName ? state.serviceName : n;
      let params = {
        ...state.params,
        search: state.search,
        page: state.activePageIndex - 1,
        pageSize: state.rows,
      };
      dispatch({ type: actions.SET_PROCESS, payload: true });
      api
        .get(url, { params })
        .then((response) =>
          dispatch({ type: actions.GET_DATA, payload: response })
        )
        .catch(() =>
          dispatch({ type: actions.GET_DATA, payload: { data: [], count: 0 } })
        )
        .finally(() => dispatch({ type: actions.SET_PROCESS, payload: false }));
    }
  };

  const setProcess = (n) => {
    dispatch({ type: actions.SET_PROCESS, payload: n });
  };

  const context = {
    ...state,
    dispatch,
    goNextPage,
    goPrevPage,
    goSelectedPage,
    setSelectedItem,
    setRows,
    setProcess,
    setSearch,
    getData,
  };

  return (
    <FormularioContext.Provider value={context}>
      <div className="cb-component">{children}</div>
    </FormularioContext.Provider>
  );
};

export default Formulario;
