import React, { useState } from 'react';
import Texto from '../commons/Texto';
import Combo from '../commons/Combo';

import '../css/footer.css';

const Footer = () => {
  const [solicitud, setSolicitud] = useState({
    nm: '',
    co: '',
    tl: '',
    tp: '',
    ms: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSolicitud((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="cb-footer">
      <div className="cb-footer-contendor">
        <div className="cb-logo"></div>
        <div className="cb-redes">
          <span>
            <i className="fa fa-facebook fa-lg" aria-hidden="true"></i>
          </span>
          <span>
            <i className="fa fa-instagram fa-lg" aria-hidden="true"></i>
          </span>
        </div>
        <div className="cb-phone">
          <span>
            <i className="fa fa-mobile fa-lg" aria-hidden="true"></i>
          </span>
          <div>¿Tiene alguna pregunta?</div>
          <div>(+51) 941 361 507</div>
        </div>
        <div className="cb-email">
          <span>
            <i className="fa fa-envelope fa-lg" aria-hidden="true"></i>
          </span>
          <div>Env&iacute;anos un correo</div>
          <div>contacto@finanser.com.pe</div>
        </div>
        <div className="cb-address">
          <span>
            <i className="fa fa-map-marker fa-lg" aria-hidden="true"></i>
          </span>
          <div>
            Calle Germ&aacute;n Schreiber 276, Oficina 240, Urb. Santa Ana, San
            Isidro, Lima - Perú
          </div>
        </div>
        <div className="cb-form">
          <input
            name="nm"
            value={solicitud.nm}
            placeholder="Nombre"
            tabIndex={200}
            onChange={handleChange}
          />
          <input
            name="co"
            value={solicitud.co}
            placeholder="Correo"
            tabIndex={201}
            onChange={handleChange}
          />
          <input
            name="tl"
            value={solicitud.tl}
            placeholder="Tel&eacute;fono"
            tabIndex={202}
            onChange={handleChange}
          />
          <select
            name="tp"
            value={solicitud.tp}
            tabIndex={204}
            onChange={handleChange}
          >
            <option hidden>Emprendedor / Inversionista</option>
            <option value={'01'}>Emprendedor</option>
            <option value={'02'}>Inversionista</option>
          </select>
          <textarea
            name="ms"
            value={solicitud.ms}
            placeholder="Mensaje"
            tabIndex={205}
            onChange={handleChange}
          />
          <button type="button" tabIndex={206}>
            Enviar
          </button>
          <div>
            <input id="chkConfirm" type="checkbox" />
            <label htmlFor="chkConfirm">
              He le&iacute;do las condiciones de tratamiento de mis datos
              personales y autorizo de forma expresa su uso
            </label>
          </div>
        </div>
        <div className="cb-legal">
          <div></div>
          <label>AVISO LEGAL</label>
          <p>
            Finanser administra una plataforma de pr&eacute;stamos P2P, a
            trav&eacute;s de la cual un inversionista registrado otorga un
            cr&eacute;dito a otro usuario registrado cuya empresa a la cual
            representa cumple con alguna caracter&iacute;stica de
            sostenibilidad, o que, en todo caso, desee introducirse en ese
            camino. Asimismo, Finanser se encuentra inscrita como empresa de
            pr&eacute;stamos en el “Registro de Empresas y Personas que
            efect&uacute;an Operaciones Financieras o de Cambio de Moneda” de la
            Superintendencia de Banca, Seguros y AFP (SBS). Finanser no realiza
            actividad de financiamiento participativo financiero (crowdfunding)
            regulado en el T&iacute;tulo IV del Decreto de Urgencia N° 013-2020
            y el Reglamento de la Actividad de Financiamiento Participativo
            Financiero y sus Sociedades Administradoras, aprobado por
            Resoluci&oacute;n N° 045-2021-SMV/02. Finanser cuenta con controles
            en materia de prevenci&oacute;n del lavado de activos y
            financiamiento del terrorismo, cumpliendo con los est&aacute;ndares
            de la industria y como sujeto obligado a reportar a la Unidad de
            Inteligencia Financiera (UIF). Finanser no recibe dep&oacute;sitos
            ni realiza intermediaci&oacute;n financiera. Los fondos de los
            prestamistas y/o prestatarios no pasan en ning&uacute;n momento por
            cuentas de titularidad de Finanser o sus representantes. Finanser no
            representa a los prestamistas y/o prestatarios, quienes
            voluntariamente se registran en la plataforma y deciden realizar
            operaciones crediticias de acuerdo con sus intereses particulares.
            Verificamos la identidad de todos nuestros usuarios. Finanser es una
            marca registrada en INDECOPI.
          </p>
          <label>Todos los derechos reservados 2022. Finanser</label>
        </div>
      </div>
    </div>
  );
};

export default Footer;
