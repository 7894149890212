import React from 'react';
import HtmlToReact from 'html-to-react';

import Boton from '../../commons/Boton';
import Carrusel from '../../commons/Carrusel';
import CarruselBody from '../../commons/CarruselBody';
import CarruselNavigation from '../../commons/CarruselNavigation';
import ImagenOff from '../../commons/ImagenOff';
import { useTablaBody } from '../../commons/Formulario';
import { formatoFecha } from '../../commons/Utilitarios';

import { servicios } from '../../redux/helpers';

const BlogItem = () => {
  const { selected, data, setSelectedItem } = useTablaBody();

  const parse = () => {
    let htmlParser = new HtmlToReact.Parser();
    return htmlParser.parse(selected.co);
  };

  return (
    <div className="cb-blog-seleccionado">
      <h1>{selected.ti}</h1>
      <span>{selected.si}</span>
      <div className="cb-blog-seleccionado-contenido">
        <div className="contenido">
          <div className="imagenes">
            {selected.adj && selected.adj.dtl && selected.adj.dtl.length > 0 ? (
              <Carrusel>
                <CarruselBody>
                  {selected.adj.dtl.map((x) => (
                    <img
                      key={x.co}
                      src={`${servicios.ARCHIVO}/${selected.adj.ar}/detalle/${x.co}`}
                      alt={x.ti}
                      loading={'eager'}
                    />
                  ))}
                </CarruselBody>
                <CarruselNavigation />
              </Carrusel>
            ) : (
              <div className="imagen nodisponible">
                <ImagenOff />
              </div>
            )}
          </div>
          <span>{formatoFecha(selected.fc)}</span>
          <div className="texto">{parse()}</div>
        </div>
        <div className="ultimos">
          <h3>&Uacute;LTIMAS NOTICAS</h3>
          {data
            .filter((x) => x.bl !== selected.bl)
            .map((x) => (
              <div key={x.bl} className="cb-ultimos-item">
                {x.adj && x.adj.dtl && x.adj.dtl.length > 0 ? (
                  <img
                    src={`${servicios.ARCHIVO}/${x.adj.ar}/detalle/${x.adj.dtl[0].co}`}
                    alt={x.ti}
                    width={170}
                    loading={'eager'}
                    onClick={() => setSelectedItem(x)}
                  />
                ) : (
                  <div
                    className="imagen nodisponible"
                    onClick={() => setSelectedItem(x)}
                  >
                    <ImagenOff />
                  </div>
                )}
                <div>{x.ti}</div>
              </div>
            ))}
        </div>
      </div>
      <Boton
        label={'Regresar lista'}
        className="accion"
        onClick={() => setSelectedItem(null)}
      />
    </div>
  );
};

export default BlogItem;
