import { combineReducers } from 'redux';

import { appReducer } from './app.reducer';
import { clienteReducer } from './cliente.reducer';

const reducers = combineReducers({
  app: appReducer,
  cliente: clienteReducer,
});

export default reducers;
