import { constantes, servicios } from '../helpers/index';
import { api } from '../axios/api';
import { encriptado } from '../../commons/Utilitarios';

export const APP_PROCESS = '[App] app procesando';
export const LOGIN_ON = '[App] login activo';
export const LOGIN_OFF = '[App] login inactivo';
export const LOGIN_SUCCESS = '[App] login existoso';
export const LOGIN_ERROR = '[App] login error';
export const LOGOUT = '[App] logout';
export const CAPTCHA_SUCCESS = '[App] captcha exitoso';
export const CAPTCHA_FAIL = '[App] captcha fail';

export const isLogginIn = () => (dispatch) => {
  let currentUser = localStorage.getItem('currentUser');
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    dispatch({
      type: LOGIN_ON,
      payload: currentUser,
    });
  } else {
    dispatch({
      type: LOGIN_OFF,
    });
  }
};

export const getCaptchaAction = () => (dispatch) => {
  dispatch({ type: APP_PROCESS });
  api
    .get(servicios.CAPTCHA)
    .then((response) => {
      dispatch({
        type: CAPTCHA_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: CAPTCHA_FAIL,
        payload: error.data.detail,
      });
    });
};

export const putCaptchaAction = (ct) => (dispatch) => {
  dispatch({ type: APP_PROCESS });
  api
    .put(`${servicios.CAPTCHA}/${ct}`)
    .then((response) => {
      dispatch({
        type: CAPTCHA_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: CAPTCHA_FAIL,
        payload: error.data.detail,
      });
    });
};

export const postLoginAction = (user) => (dispatch, getState) => {
  let { captcha } = getState().app;
  dispatch({ type: APP_PROCESS });

  let usuario = {
    ap: constantes.APLICACION,
    ct: captcha.ct,
    pw: encriptado(user.pd),
    us: user.us,
    tx: user.tx,
  };

  api
    .post(servicios.LOGIN, usuario)
    .then((response) => {
      localStorage.setItem('currentUser', JSON.stringify(response));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      if (error.data.detail) {
        dispatch({
          type: LOGIN_ERROR,
          payload: error.data.detail,
        });
        dispatch(putCaptchaAction(captcha.ct));
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: 'Caracteres incorrectos',
        });
        dispatch({
          type: CAPTCHA_SUCCESS,
          payload: {
            ct: error.data.errors.ct,
            si: error.data.errors.si,
            img: error.data.errors.img,
          },
        });
      }
    });
};

export const logoutAction = () => (dispatch) => {
  localStorage.removeItem('currentUser');
  dispatch({
    type: LOGOUT,
  });
};
